import { ColorScheme, MantineProvider } from '@mantine/core';
import { DatesProvider } from '@mantine/dates';
import { useColorScheme, useLocalStorage } from '@mantine/hooks';
import { useMantineTheme } from '@mantine/styles';
import { ColorConfigurationValue, DEFAULT_MAP_CENTER, DateInputFieldDefaultProps, EndpointAccess, GoogleMapsAPILoaderConfig, GridGlobalStyles, LoginModalForm, MicroMClient, MicroMRouter, ModalsManager, PublicEndpoint, UseLocaleFormatDefaultProps, useIsLoggedIn, useOpenForm } from '@mcurros2/microm';
import 'dayjs/locale/en';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { API_URL, APP_ID } from '../config';
import { RealEstateAgentsProfile } from '../Entities/RealEstateAgentsProfile/RealEstateAgentsProfile';
import { AgentListClaimTypes } from './AgentListClaimTypes';
import { LoggedInMenu } from './LoggedInMenu';
import { SubscribeNewsletter } from './SubscribeNewsletter';
import { NewsletterSubscriptions } from '../Entities/NewsletterSubscriptions/NewsletterSubscriptions';

const publicEndpoints: Record<string, PublicEndpoint> = {
    NewsletterSubscriptions: {
        EntityName: 'NewsletterSubscriptions',
        AllowedAccess: EndpointAccess.Create,
    }
}

const client = new MicroMClient({ api_url: API_URL, app_id: APP_ID, publicEndpoints: publicEndpoints });

DateInputFieldDefaultProps.valueFormat = 'MM/DD/YYYY';

export function AppLogin() {
    const theme = useMantineTheme();
    const [themeColor, setThemeColor] = useState<ColorConfigurationValue>({ colorKey: theme.primaryColor, colorShade: theme.primaryShade });
    const preferredColorScheme = useColorScheme();

    GoogleMapsAPILoaderConfig.apiKey = "AIzaSyDYR3ePatdxpa5UKy-SC1-7ZXp_cPOJr8Q";

    UseLocaleFormatDefaultProps.initialLocale = 'en-US';
    DEFAULT_MAP_CENTER.lat = 37.09024;
    DEFAULT_MAP_CENTER.lng = -95.712891;

    const [colorScheme, setColorScheme] = useLocalStorage({
        key: 'mantine-color-scheme',
        defaultValue: preferredColorScheme,
        getInitialValueInEffect: true,
    });

    const toggleColorScheme = (value?: ColorScheme) => {
        setColorScheme(value || (colorScheme === 'dark' ? 'light' : 'dark'))
    };


    const modalOverlayProps = {
        overlayProps: {
            color: colorScheme === 'dark' ? theme.colors.dark[3] : theme.colors.gray[9],
            opacity: 0.55,
            blur: 3,
        }
    };

    const [content, setContent] = useState<ReactNode>(<></>);
    const { isLoggedIn, setIsLoggedIn, loggedInInfo } = useIsLoggedIn({ client });

    const [showLoginModal, setShowLoginModal] = useState(false);

    const [aglClaims, setAglClaims] = useState<AgentListClaimTypes>();
    const [showBackoffice, setShowBackoffice] = useState(false);

    useEffect(() => {
        let isMounted = true;

        const checkBackoffice = async () => {
            try {
                const menus: Set<string> = await client.getMenus();
                if (isMounted) {
                    const hasBackoffice = [...menus].some((menu) => menu.startsWith("BackofficeMenu_"));
                    setShowBackoffice(hasBackoffice);
                }
            } catch (error) {
                console.error("Error getting menus:", error);
            }
        };

        const agl_info = loggedInInfo as AgentListClaimTypes;
        agl_info.vc_photourl = client.getDocumentURL(agl_info.vc_photoguid || '');

        setAglClaims({ ...agl_info });
        checkBackoffice();

        return () => {
            isMounted = false;
        };
    }, [loggedInInfo]);


    const [openNewsletter, setOpenNewsletter] = useState(false);

    // use effect to bind buttons to newsletter subscriptions
    useEffect(() => {
        const findAnAgent = document.getElementById('findAnAgentButton');
        const referAClientButton = document.getElementById('referAClientButton');
        const searchButton = document.getElementById('searchButton');
        const chooseUsButton = document.getElementById('chooseUsButton');
        const referralButton = document.getElementById('referralButton');


        const handleClick = () => setOpenNewsletter(true);

        if (findAnAgent) findAnAgent.addEventListener('click', handleClick);
        if (referAClientButton) referAClientButton.addEventListener('click', handleClick);
        if (searchButton) searchButton.addEventListener('click', handleClick);
        if (chooseUsButton) chooseUsButton.addEventListener('click', handleClick);
        if (referralButton) referralButton.addEventListener('click', handleClick);

        return () => {
            if (findAnAgent) findAnAgent.removeEventListener('click', handleClick);
            if (referAClientButton) referAClientButton.removeEventListener('click', () => handleClick);
            if (searchButton) searchButton.removeEventListener('click', handleClick);
            if (chooseUsButton) chooseUsButton.removeEventListener('click', handleClick);
            if (referralButton) referralButton.removeEventListener('click', handleClick);
        };
    }, []);

    const PortalContent = useMemo(() => {
        return isLoggedIn ? (
            <LoggedInMenu
                showBackoffice={showBackoffice}
                colorScheme={colorScheme}
                toggleColorScheme={toggleColorScheme}
                loggedInUser={aglClaims?.username}
                initials={aglClaims?.userinitials}
                photoURL={aglClaims?.vc_photourl}
                onLogOut={async () => {
                    await client.logoff();
                    setIsLoggedIn(false);
                    setShowLoginModal(false);
                }}
                onEditProfile={async (openform) => {
                    if (!aglClaims) return;
                    const profile = new RealEstateAgentsProfile(client);
                    await openform({
                        entity: profile,
                        initialFormMode: 'edit',
                        title: 'Edit',
                        modalFormSize: 'fullscreen',
                        getDataOnInit: true,
                        onModalClosed: () => {
                            const new_claims = client.LOGGED_IN_USER as Partial<AgentListClaimTypes> | undefined;
                            setAglClaims(new_claims ? { ...new_claims } as AgentListClaimTypes : undefined);
                        },
                    });
                }}
            />
        ) : (
            <button className="log-in-wrapper" onClick={() => setShowLoginModal(true)}>
                <div className="log-in">Log in</div>
            </button>
        );
    }, [isLoggedIn, showBackoffice, colorScheme, aglClaims, setIsLoggedIn]);


    return (
        <MicroMRouter>
            <MantineProvider theme={{
                colorScheme: colorScheme,
                focusRing: "auto",
                primaryColor: themeColor.colorKey,
                primaryShade: themeColor.colorShade
            }} withCSSVariables>
                <GridGlobalStyles />
                <DatesProvider settings={{ locale: 'en' }}>
                    <ModalsManager modalProps={modalOverlayProps} animationDuration={0}>
                        {!isLoggedIn &&
                            <div className="login-button">
                                <button onClick={async () => setShowLoginModal(true)}>Login</button>
                            </div>
                        }
                        {isLoggedIn &&
                            <LoggedInMenu
                                showBackoffice={showBackoffice}
                                colorScheme={colorScheme}
                                toggleColorScheme={toggleColorScheme}
                                loggedInUser={aglClaims?.username}
                                initials={aglClaims?.userinitials}
                                photoURL={aglClaims?.vc_photourl}
                                onLogOut={async () => {
                                    await client.logoff();
                                    setIsLoggedIn(false);
                                    setShowLoginModal(false);
                                }}
                                onEditProfile={async (openform) => {
                                    if (!aglClaims) return;
                                    const profile = new RealEstateAgentsProfile(client);

                                    await openform({
                                        entity: profile,
                                        initialFormMode: 'edit',
                                        title: 'Edit',
                                        modalFormSize: 'fullscreen',
                                        getDataOnInit: true,
                                        onModalClosed: () => {
                                            const new_claims: Partial<AgentListClaimTypes> | undefined = client.LOGGED_IN_USER;
                                            setAglClaims({ ...new_claims } as AgentListClaimTypes);
                                        },
                                    });

                                }}

                            />
                        }
                        <LoginModalForm
                            client={client}
                            overlayProps={modalOverlayProps.overlayProps}
                            transitionProps={{ transition: 'pop' }}
                            onLoggedIn={() => { setIsLoggedIn(true); }}
                            onClose={() => setShowLoginModal(false)}
                            openState={showLoginModal}
                        />
                        <SubscribeNewsletter
                            client={client}
                            openState={openNewsletter}
                            setOpenState={setOpenNewsletter}
                        />
                    </ModalsManager>
                </DatesProvider>
            </MantineProvider>
        </MicroMRouter>
    );
}


